.ACCW_MessegeUserComponent_messege_wrapper_user {
    padding: 0 10px;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
    padding-bottom: 20px;

    grid-template-columns: 1fr minmax(0px, 724px) 1fr;
    display: grid;
    justify-items: end;
}
.ACCW_MessegeUserComponent_messege_wrapper_user:first-child {
    padding-top: 30px;
}

.ACCW_MessegeUserComponent_messege_wrapper_user:last-child {
    padding-bottom: 0px;
}

.ACCW_MessegeUserComponent_messege_wrapper_stack {
    padding-bottom: 5px;
}

.ACCW_MessegeUserComponent_messege_wrapper_stack .ACCW_MessegeUserComponent_arrow_right {
    display: none;
}

.ACCW_MessegeUserComponent_messege_wrapper_stack .ACCW_MessegeUserComponent {
    border-radius: 10px 0px 0px 10px;
}

.ACCW_MessegeUserComponent {
    background-color: #edf5ff;
    text-align: center;

    border-radius: 10px 0px 0px 10px;
    position: relative;
    max-width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
}

.ACCW_MessegeUserComponent_arrow_right {
    position: absolute;
    right: -10px;
    bottom: 0px;
}

.ACCW_MessegeUserComponent_text_messege {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-size: 1em;
    font-weight: 300;
    text-align: left;
    word-break: break-word;
    display: flex;
}

.ACCW_MessegeBotComponent_messege_wrapper_img_wrapper {
    display: flex;
    align-items: flex-end;
    background-color: #fafafa;
    padding-left: 13px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.ACCW_MessegeUserComponent_status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 50px;
    padding: 10px 0;
    padding-bottom: 13px;
    padding-left: 20px;
}

.imgMessegeWraperFalse {
    display: flex;
    align-items: center;
}

.imgMessegeWraperFalse img {
    margin-right: 10px;
}

.imgMessegeWraperFalse img {
    cursor: pointer;
}

.ACCW_MessegeUserComponent_not_sending {
    background-color: #00bffe;
}

.ACCW_MessegeUserComponent_not_sending .ACCW_TextMessegeComponent_Text_userMess {
    color: white;
}

.ACCW_MessegeUserComponent_messege_wrapper_status {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ACCW_MessegeUserComponent_status_timestamp {
    font-family: 'Montserrat';
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 400;
}

.ACCW_MessegeUserComponent_message_gradien {
    width: 100%;
    background-image: linear-gradient(to right, #edf5ff 0%, rgba(237, 245, 255, 0) 100%);
}

.ACCW_gradient .ACCW_TextMessegeComponent_Text_userMess {
    color: #ffffff;
    font-weight: 400;
}

.ACCW_gradient .ACCW_MessegeUserComponent_status_timestamp {
    color: rgba(255, 255, 255, 0.54);
}

.ACCW_gradient .ACCW_TextMessegeComponent_Text {
    color: #ffffff;
    font-weight: 400;
}

.ACCW_gradient .ACCW_MessegeBotComponent_messege_wrapper_img_wrapper {
    background-color: rgba(255, 255, 255, 0.2);
}

.ACCW_gradient .ACCW_MessegeBotComponent {
    background-color: rgba(255, 255, 255, 0.2);
}

.ACCW_gradient .ACCW_MessegeBotComponent_messege_wrapper_user_gradient {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2));
}

.ACCW_gradient .ACCW_MessegeBotComponent_timestamp {
    color: rgba(255, 255, 255, 0.54);
}

.ACCW_gradient .message_link_bot {
    color: #0d1d33;
}

.ACCW_gradient .message_link_user {
    color: #0d1d33;
}
