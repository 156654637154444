.ACCW_GalleryMessageComponent{
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 10px;
    position: relative;
}

.ACCW_CardGalleryComponent_swiper_button{
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    opacity: 0.77;
    top: 20%;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}

.ACCW_CardGalleryComponent_swiper_button:hover{
    opacity: 1;
}

.ACCW_CardGalleryComponent_swiper_button:hover i{
    color: #006cff;
    opacity: 1;

}

.ACCW_GalleryMessageComponentWrapper{
    display: flex;
}

.ACCW_CardGalleryComponent_right{
    right: 10px;
}

.ACCW_CardGalleryComponent_left{
    left: 10px;

}

.ACCW_CardGalleryComponent_left i{
    margin-right: 3px;
   opacity: 0.38;

}

.ACCW_CardGalleryComponent_right i{
    margin-left: 3px;
   opacity: 0.38;
    
}

.ACCW_CardGalleryComponent{
    /* Style for "Прямоуголь" */
    min-width: 220px;
    max-width: 220px;
    min-height: 230px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swiper-button-disabled{
    /* opacity: 0;
    cursor: default; */
    display: none;
}



.gallery_button {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
}


.gallery_button .ACCW_TextMessegeComponent_button_wrapper {
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
}

.gallery_button .ACCW_MessegeButtonComponent_link {
    border-bottom: 1px solid #f2f2f2;
}

.gallery_button .ACCW_MessegeButtonComponent{
    border-bottom: 1px solid #f2f2f2;
}

.ACCW_CardGalleryComponent_img{
    max-height: 125px;
}

.ACCW_CardGalleryComponent_img_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    height: 125px;
}

.ACCW_CardGalleryComponent_text_title{
    color: #000000;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 10px;
    margin-bottom: 3px;
}

.ACCW_CardGalleryComponent_text_description{
    margin: 0;
    padding: 0;
    color: #000000;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    word-break: break-word;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    padding-right: 10px;
}

.ACCW_CardGalleryComponent_text_wrapper{
    padding-top: 10px;
    padding-left: 10px;
}