@media screen and (max-width: 460px) {
  .ACCW_Footer_Hide {
    display: none;
  }
}

.ACCW_FooterComponent {
  height: 60px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  display: flex;
  max-width: 724px;
  margin: 0 auto;
}

.ACCW_FooterComponent_persistent_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}

.ACCW_FooterComponent_persistent_button_img_wraper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ACCW_FooterComponent_input_button {
  display: flex;
  align-items: center;
}

.ACCW_FooterComponent_input_button_wraper {
  -webkit-flex: 1 1;
  flex: 1 1;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sand_scale {
  transform: scale(1.2);
}

.ACCW_FooterComponent_input_button_image_wraper {
  height: 40px;
  width: 40px;
}

.ACCW_FooterComponent_input_button_send_wraper {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  padding-left: 3px;
  display: flex;
  margin-right: 10px;
  transition: all 0.2s;
}

.ACCW_FooterComponent_input_button_send_wraper:hover {
  background-color: rgba(0, 108, 255, 0.07);
}

.ACCW_FooterComponent_input_button_image_img {
  height: 100%;
}
.ACCW_FooterComponent_input_button_send_img {
  height: 24px;
  margin: auto;
  transition: all 0.3s;
}

.ACCW_FooterComponent_chat_input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ACCW_ChatInputComponent_form {
  flex: 1;
  display: flex;
}
.ACCW_ChatInputComponent_input,
.accw_input_gradient__FALSE {
  flex: 1;
  padding: 10px 0px 0px 0px;
  font-family: "Montserrat", sans-serif;
  border: none;
  opacity: 0.87;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  /* background: none; */
}

.ACCW_ChatInputComponent_input::-webkit-scrollbar,
.accw_input_gradient__FALSE::-webkit-scrollbar {
  display: none !important;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
}

.ACCW_ChatInputComponent_input_locked {
  text-align: center;
  /* margin-right: 15px; */
  line-height: 25px;
}

.VoiceComponent_main {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.VoiceComponent_main i {
  font-size: 24px;
}

.audio_anim {
  height: 30px;
  cursor: pointer;
}

.audioStop {
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #006cff;
  background: white;
  opacity: 0;
  transition: all 0.3s;
  font-size: 12px;
}

.VoiceComponent_main:hover .audioStop {
  opacity: 1;
}
