.ACCW_QuickRepliesComponent_main_wrapper_button{
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px 0;
}

.AACW_qr_garadient .ACCW_QuickReplieComponent_main{

}