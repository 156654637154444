.ACCW_FrameWrapperComponent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ACCW_FrameWrapperComponent_content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    height: 1px;

}

.ACCW_FrameWrapperComponent_fotter {
    flex: 0 0 auto;
}

.ACCW_ConversationByComponent {
    height: 25px;
    background-color: rgba(0, 0, 0, 0.02);
    display: flex;
    justify-content: center;
    align-items: center;

}

.ACCW_ConversationByComponent span {
    /* Style for "Conversati" */
    opacity: 0.54;
    color: #000000;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.ACCW_ConversationByComponent a {
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
}