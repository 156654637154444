.ACCW_Button_Main {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ACCW_Button_IMG_wrapper {
    height: 90%;
    width: 90%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
}

.ACCW_Button_IMG {
    height: 20%;
    transition: all 0.2s;
}

.ACCW_Button_IMG-animation {
    height: 100%;
}
.ACCW_Button_IMG_close {
    height: 30%;
    transition: all 0.2s;
}

.ACCW_Button_IMG_close-turn {
    transform: rotateZ(90deg);
}

.ACCW_NotificationComponent {
    position: absolute;
    z-index: 10;
    width: 22px;
    height: 22px;
    background-color: #ff6f61;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 50%;
    top: -2px;
    right: 0px;
}

.ACCW_NotificationComponent_span {
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.ACCW_LastImgComponent {
    position: absolute;
    bottom: 70px;
    right: 1px;

    max-width: 375px;
    transition: all 0.4s;
}

.ACCW_LastImgComponent_wrappers {
    width: 295px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    position: relative;
}

.ACCW_LastImgComponent_message {
    flex: 1;
    display: flex;
    align-items: center;
}

.ACCW_LastImgComponent_close_button {
    min-width: 23px;
    min-height: 23px;
    max-width: 25px;
    max-height: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    right: -10px;
    background: #ff6f61;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
}

.ACCW_LastImgComponent_close_button img {
    width: 10px;
}

.ACCW_LastImgComponent_message_text {
    margin: 0;
    padding: 0;

    cursor: pointer;
    text-align: left;

    color: #000000;
    font-family: 'Montserrat', sans-serif;

    font-size: 12px;
    font-weight: 400;
    padding-left: 22px;
    white-space: pre-wrap;
    max-width: 295px;
    padding-top: 15px;
    padding-bottom: 15px;
    word-break: break-word;
    padding-right: 22px;
}

@media screen and (max-width: 650px) {
}
