.ACCW_TextMessegeComponent_Text_wrapper{
    padding: 15px 29px;
    padding-left: 0;
    padding-bottom: 0;
}

.ACCW_TextMessegeComponent_Text_wrapper_userMess{
    padding: 13px 21px;
    padding-right: 0px;
    margin-right: 60px;
    padding-bottom: 0;
}

.ACCW_TextMessegeComponent_Text{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    word-break: break-word;
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    justify-content: center;
    white-space: pre-wrap;
    color: #0d1d33;

    display: block;
}

.ACCW_TextMessegeComponent_Text_userMess{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    word-break: break-word;
    display: flex;
    width: 100%;
    /* flex-direction: column; */
    justify-content: center;
    color: #000000;
    white-space: pre-wrap;

    display: block;
}

.ACCW_TextMessegeComponent_button_r{
    padding-right: 29px;
    padding-top: 10px;
}

.ACCW_TextMessegeComponent_button_wrapper{
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
    border-radius: 4px;
    overflow: hidden;
}






.message_link_bot{
    color: #5e8ea4;
    text-decoration: none;
    transition: all 0.2s;
}

.message_link_user{
    color: #5e8ea4;
    text-decoration: none;
    transition: all 0.2s;

}
.message_link_bot:hover{
    text-decoration: underline;
}

.message_link_user:hover{
    text-decoration: underline;

}


.mark_ACCW_italic{
    font-style: italic;
    background: none;
}

.mark_ACCW_bold{
    font-weight: bold;
    background: none;

}