
.file_upload_component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}
#input_file {
  display: none;
}
.input_file_label_image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
}

/* .input_file_label_image_wrapper:hover{
  background-color: rgba(0,108,255, 0.07);
} */
.input_file_label_image {
  width: 24px;
  height: 24px;
}
.input_file_label_image_loading {
  animation: fileUpload 1s infinite

}

@keyframes fileUpload {
  from {
    transform: translateY(0) ;
  }
  to {
    transform: translateY(-200%) ;
  }
}