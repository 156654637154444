.ACCW_QuickReplieComponent_main{
  background-color: #edf5ff;
  border: 1px solid #f2f2f2;
  /* height: 40px; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 40px;
  margin: 5px;
  cursor: pointer;
}

.ACCW_QuickReplieComponent_main_text{
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  word-break: break-all;
}
