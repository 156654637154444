.ACCW_ConversationComponent {
    flex: 1;
    height: 1px;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    position: relative;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 5px;


    /* overflow: auto;
    overflow-y: auto;
    overflow-y: overlay; */
    /* overflow: auto; */
}

@-moz-document url-prefix() {
    .ACCW_ConversationComponent {
        flex-direction: column;
        scrollbar-color: #bed1e8 #edf5ff;
        scrollbar-width: thin;
    }
}
.ACCW_ConversationComponent_revers {
    height: 100%;
}

.ACCW_ConversationComponent::-webkit-scrollbar {
    width: 4px;
    display: none;
    transition: all 0.3s;
}

.ACCW_ConversationComponent:hover::-webkit-scrollbar {
    display: block;
}

.ACCW_ConversationComponent::-webkit-scrollbar-track {
    border-radius: 20px;
    background: #edf5ff;
}

.ACCW_ConversationComponent::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #bed1e8;
}

.ACCW_ConversationComponent::-webkit-scrollbar-thumb:hover {
    /* background: #edf5ff;  */
}

/* for meesege wraper */

.ACCW_UnreadMessege_messeg {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    /* margin-bottom: -30px;
    margin-top: -20px; */
    height: 30px;
    align-items: center;
}

.ACCW_UnreadMessege_messeg_text {
    background-color: #f7f7f7;
    border-radius: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 20px;
    padding: 0 5px;
    display: flex;
    align-items: center;
}

.ACCW_UnreadMessege_messeg_textStyle {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.87;
}

.ACCW_ErrorComponent_main {
    position: absolute;
    width: 100%;
    min-height: 21px;
    background: #ff6f61;
    z-index: 10;
    top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ACCW_ErrorComponent_Text {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 12px;
}

.ACCW_MessegeBotComponent_messege_wrapper_user_gradient {
    width: 100%;
    background-image: linear-gradient(to right, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}

.accw_input_gradient::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.87);
}
.accw_input_gradient::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.87);
}
.accw_input_gradient:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.87);
}
.accw_input_gradient:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.87);
}
