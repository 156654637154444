.ACCW_HeaderComponent{
  height: 60px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1);0 */
  display: grid;
   grid-template-columns: 60px minmax(0px, 3000px) 60px ;
   /* background-image: linear-gradient(to right, #006cff 0%, #00bfff 100%); */
}

.ACCW_HeaderComponent_wrapper{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: flex-end;
}

.ACCW_HeaderComponent_title{
  
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.ACCW_HeaderComponent_description{
  opacity: 0.87;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  margin-top: 7px;
}

.ACCW_HeaderComponent_img_wrapper{
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.2s;
  display: flex;
}
.ACCW_HeaderComponent_img_wrapper:hover{
  background:rgba(255,255,255, 0.38);

}


.ACCW_HeaderComponent_img{
  height: 14px;
  margin: auto;
}

.ACCW_HeaderComponent_i{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ACCW_HeaderComponent_AC_link{
  text-decoration: underline;
  opacity: 0.87;
  color: #ffffff;
}

.ACCW_HeaderComponent_wrapper_avatar{
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.ACCW_HeaderComponent_avatar_one{
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  background: white;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.ACCW_HeaderComponent_avatar_img{
  margin: auto;
  height: 100%;
}