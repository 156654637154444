.ACCW_MessegeBotComponent_messege_wrapper_user{
    padding: 0 10px;
    padding-bottom: 20px;
    display: flex;
    padding-left: 0px;

    grid-template-columns: 1fr minmax(0px, 724px) 1fr;
    display: grid;
    /* justify-items: end; */
}
.ACCW_MessegeBotComponent_messege_wrapper_user:first-child{
    padding-top: 30px;
  }

.ACCW_MessegeBotComponent_messege_wrapper_user:last-child{
    padding-bottom: 0px;
}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack{
    padding-bottom: 5px;

}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack .ACCW_MessegeBotComponent_arrow_right{
    display: none;
}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack .ACCW_MessegeBotComponent{
    border-radius: 0px 10px 10px 0px;

}

.ACCW_MessegeBotComponent_messege_wrapper_user_stack .ACCW_MessegeBotComponent_messege_wrapper-avatar{
    opacity: 0;
}


.ACCW_MessegeBotComponent_messege_wrapper-avatar{
    height: 40px;
    width: 40px;
    display: flex;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
}

.ACCW_MessegeBotComponent_messege_avatar{
    height: 100%;
    margin: auto;
}

.ACCW_MessegeBotComponent{
    /* background-color: #f7f9fa; */
    border-radius: 0px 10px 10px 0px;
    position: relative;
    max-width: calc( 100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
    padding-bottom: 10px;
}

.ACCW_MessegeBotComponent_arrow_right{
    position: absolute;
    bottom: 0px;
    left: -10px;
}

/* delete */
.ACCW_MessegeBotComponentt_text_messege{
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    word-break: break-word;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.ACCW_MessegeBotComponent_messege_wrapper_typing:last-child .ACCW_MessegeBotComponent_messege_avatar{
    opacity: 0;
}

.ACCW_MessegeBotComponent_messege_wrapper_typing:last-child .ACCW_MessegeBotComponent{
    border-radius: 10px 10px 10px 10px;
}
.ACCW_MessegeBotComponent_messege_wrapper_typing:last-child .ACCW_MessegeBotComponent_arrow_right{
    display: none;
}
.ACCW_MessegeBotComponent_messege_avatar_typing{
    height: 90%;
    margin: auto;
}

.ACCW_MessegeBotComponent_arrow_right_typing{
    position: absolute;
    bottom: 0px;
    left: -10px;
}

.ACCW_MessegeBotComponent_messege_wrapper_typing_border .ACCW_MessegeBotComponent{
    
    border-radius: 10px 10px 10px 0px!important;
}

.ACCW_MessegeBotComponent_timestamp{
    font-family: 'Montserrat', sans-serif;
    color: rgba(0,0,0, 0.54);
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px;
}