.ACCW_LoaderComponent{
  height: 0px;
  display: flex;
  justify-content: center;
}
/* windmill */
.spinner {
  width: 25px;
  height: 25px;
  position: relative;
  margin-top: 5px;
}

.cube1, .cube2 {
  background-color: #f99b1b;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(14px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(14px) translateY(14px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(14px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% { 
    transform: translateX(14px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(14px) rotate(-90deg) scale(0.5);
  } 50% { 
    transform: translateX(14px) translateY(14px) rotate(-179deg);
    -webkit-transform: translateX(14px) translateY(14px) rotate(-179deg);
  } 50.1% { 
    transform: translateX(14px) translateY(14px) rotate(-180deg);
    -webkit-transform: translateX(14px) translateY(14px) rotate(-180deg);
  } 75% { 
    transform: translateX(0px) translateY(14px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(14px) rotate(-270deg) scale(0.5);
  } 100% { 
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}


/* windmill end */

/* cube */
.sk-cube-grid {
  width: 30px;
  height: 30px;
  margin: 20px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #f99b1b;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
          border-radius: 5px 0 0 0;
        }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
        
        }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
          border-radius: 0 5px 0 0;
        
        }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
          border-radius: 0 0 0 5px;
         }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; 
          border-radius: 0 0 5px 0;
        
        }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}

/* cube */