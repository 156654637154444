.ACCW_MessegeButtonComponent{
    width: 100%;
    padding: 12px 0;
    display: flex;
    justify-content: center;
 
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;

    position: relative;
    z-index: 2;
}

.ACCW_MessegeButtonComponent_link{
    width: 100%;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;
    text-decoration: none;
    position: relative;
    z-index: 2;
}

.ACCW_MessegeButtonComponent_text_link{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    word-break: break-word;
    padding: 0 30px;

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ACCW_MessegeButtonComponent_text{

    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    word-break: break-word;
    padding: 0 12px;
}

.ACCW_MessegeButtonComponent_main{
    position: relative;
    background: black;
}

.ACCW_MessegeButtonComponent_animation{
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
    /* background: rgba(250,250,250, 0.5);  */
    background: rgba(187, 225, 239, 0.3);
    
     /* background: rgba(0,0,0, 0.3); */
    z-index: 1;
    /* top: 100%;
    left: 100%; */
    /* bottom: 0; */
    /* transform: translate(-50%, -50%); */
    transition: all 0.6s;
    opacity: 0;
    top: 0;
    left: 0;
}

.ACCW_MessegeButtonComponent_main:active .ACCW_MessegeButtonComponent_animation{
    /* height: 100%;
    width: 100%; */
    transition: all 0s;
    opacity: 1;

}