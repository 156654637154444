.ACCW_TypingComponent{
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: space-between;
  margin: 15px 5px;
  margin-bottom: 7px;
  margin-right: 29px;
}

.ACCW_TypingComponent_point{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #00bffe;
}

.ACCW_gradient .ACCW_TypingComponent_point{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(255,255,255, 0.54);
}


.ACCW_TypingComponent_point1{
  animation: typingAnimation 1.7s infinite;
  animation-delay: -0.7s
}

.ACCW_TypingComponent_point2{
  animation: typingAnimation 1.7s infinite;
  animation-delay: -0.55s
}
.ACCW_TypingComponent_point3{
  animation: typingAnimation 1.7s infinite;
  animation-delay: -0.4s
}




@keyframes typingAnimation{
  10%{
    transform: translateY(-120%)
  }
  50%{
    transform: translateY(0)
  }
}
