.AudioPlayerComponent_player_wrapper{
    border-radius: 4px;
    display: flex;
    margin-bottom: 4px;
}

.AudioPlayerComponent_Button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.AudioPlayerComponent_play_Button img{
    height: 14px;

}

.AudioPlayerComponent_pause_Button img{
    height: 13px;
}

.AudioPlayerComponent_play_Button, .AudioPlayerComponent_pause_Button{
    cursor: pointer;
}

.AudioPlayerComponent_range_text{
    display: flex;
    align-items: center;
    justify-content: center;


}

.AudioPlayerComponent_range_text span{
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    opacity: 0.87;
    font-family: 'Roboto Mono', monospace;
    /* white-space: pre; */
}



.AudioPlayerComponent_range_audio{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 19px;
    margin-right: 15px;
}



.audioplayer_input_range {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    /* border-radius: 5px;    */
    background-color: #ffffff;
    outline: none;
    /* opacity: 0.7; */
    -webkit-transition: .2s;
    transition: opacity .2s;
    cursor: pointer;

  }
  
  .audioplayer_input_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .audioplayer_input_range::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #ffffff;
    cursor: pointer;
  }

  .AudioPlayerComponent_valuem_Button{
      display: flex;
      align-items: center;
      width: 40px;
      display: flex;
      justify-content: center;
      align-content: center;

  }

 

  .AudioPlayerComponent_range_valuem_Button_icons{
    height: 35px;
    width: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 20px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    position: absolute;
    right: 13px;
    justify-content: flex-end;
    padding: 0 10px;
}

  

  .AudioPlayerComponent_range_valuem_Button_icons:hover{
    background: rgb(3, 164, 218);
    width: 150px;

  }


  .AudioPlayerComponent_range_valuem_Button_icon_on{

    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    z-index: 2;
  }

  .AudioPlayerComponent_range_valuem_Button_iconoff{
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    z-index: 2;

  }

  .AudioPlayerComponent_range_valuem_wrapper{
   opacity: 0;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }


  .AudioPlayerComponent_little_width{
      width: 30px;
  }

  .AudioPlayerComponent_range_valuem_Button_icons:hover .AudioPlayerComponent_range_valuem_wrapper{
      opacity: 1;
  }

  .AudioPlayerComponent_title_wrapper{
    display: flex;
    margin-bottom: -10px;
  }

  .AudioPlayerComponent_title_wrapper_text span{
    font-family: Montserrat,sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    word-break: break-word;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    display: block;
  }

  .AudioPlayerComponent_download_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 10px; */
  }

  .AudioPlayerComponent_download_wrapper_img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
  }
  .AudioPlayerComponent_download_wrapper_img img {
    width: 28px;
  }
  .AudioPlayerComponent_title_wrapper_text{
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  .AudioPlayerComponent_main{
    padding-top: 10px;
    padding-right: 10px;
  }



  .VideoPlayerComponent_video_wrapper{
    position: relative;
    border-radius: 4px;
    overflow: hidden;

  }

  .VideoPlayerComponent_control_wrapper{
    position: absolute;
    height: 40px;
    border-radius: 0 0 4px 4px;
    background-color: rgba(0,191,255, 0.87);
    bottom: 4px;
    width: 100%;
    opacity: 0;
    height: 0;
    transition: all 0.3s;
    display: flex;

/* 
    opacity: 1;
    height: 40px; */
  }

  .VideoPlayerComponent_video_wrapper:hover .VideoPlayerComponent_control_wrapper {
    opacity: 1;
    height: 40px;
  }

  .VideoPlayerComponent_main{
    padding-top: 10px;
    padding-right: 10px;
  }

  .FileComponet_main{
    padding-top: 10px;
    padding-right: 10px;
    max-width: 100%;

  }

  .AudioPlayerComponent_file_name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }